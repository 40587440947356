import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const NewsList = ({ data }) => {
    const posts = data.allMdx.nodes
    return (
        <Layout>
            <Seo title="News" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>News</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col ">
                        {posts.length === 0 ? (
                            <p>There are no press releases available.</p>
                        ) : (
                            posts.map((post, idx) => {
                                const title = post.frontmatter.title || post.fields.slug
                                let devision = post.frontmatter.devision.split("")
                                devision[0] = devision[0].toUpperCase()
                                devision.join("")

                                return (
                                    <div className="row" key={idx}>
                                        <div className="col-12">
                                            <div className="card mb-3 border-0">
                                                <div className="row g-0">
                                                    <div className="col-md-4 order-1 order-md-2 d-flex justify-content-center position-relative">
                                                        <GatsbyImage
                                                            image={getImage(post.frontmatter.embeddedCoverImageLocal?.[0])}
                                                            alt=""
                                                        />
                                                        <span className="badge badge-info-news badge-primary">{devision}</span>
                                                    </div>
                                                    <div className="col-md-8 order-2 ">
                                                        <div className="card-body h-100 gray-bg d-flex flex-column">
                                                            <Link to={post.slug}>
                                                                <h5 className="card-title">{title}</h5>
                                                            </Link>
                                                            <div className="card-text d-flex mt-auto">
                                                                <span className="">{post.frontmatter.date}</span>
                                                                <span className="border-start border-delta-red-dark border-2 ms-2 ps-2">
                                                                    by {post.frontmatter.author}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NewsList

export const pageQuery = graphql`
    query HoldingsNewsList {
        allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                slug
                frontmatter {
                    author
                    devision
                    date(formatString: "Do MMMM YYYY ")
                    title
                    embeddedCoverImageLocal {
                        childImageSharp {
                            gatsbyImageData(aspectRatio: 4)
                        }
                    }
                }
            }
        }
    }
`
